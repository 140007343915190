@import "src/themes/daikiri/styles/index.scss";
.hamburger {
  display: flex;
  align-items: center;

  &__links {
    background-color: getvar($colors, "primary", "50");
    position: absolute;
    right: -105%;
    top: 0;
    height: 100vh;
    width: 100vw;
    transition: right 0.3s ease-in-out;
    z-index: 1;

    @include sm {
      background-color: transparent;
      text-align: left;
      position: relative;
      height: auto;
      width: auto;
      right: 0;
    }

    .button {
      display: block;
      margin-left: 0;
      margin-bottom: 0;
      text-align: left;

      @include md {
        display: inline-block;
        margin-bottom: 0;
        margin-left: space(3);
      }

      &__text {
        font-size: 16px;
        line-height: 24px !important;
        font-weight: 400 !important;
      }

      &--secondary {
        font-size: 12px;
        line-height: 16px;
        padding: space(0.5) space(1.75) !important;
        min-height: 40px !important;
        span {
          font-weight: 600 !important;
        }

        @include md {
          margin-left: space(1.5);
        }
      }

      &--ghost {
        color: getvar($colors, "neutral", "900");
      }

      &--isRegisterPage {
        @include md {
          padding: 0.75rem;
        }
      }

      &--signup,
      &--account {
        display: block;
        margin-left: 0;
        margin-bottom: 0;
        text-align: center;

        @include sm {
          display: inline-block !important;
          margin-bottom: 0;
          margin-left: space(3);
          min-height: 40px !important;
          padding: 0.5rem 1rem !important;
        }

        &__text {
          font-size: 16px !important;
          line-height: 24px !important;
          font-weight: 400 !important;
        }
      }
    }
  }

  &__links.dashboard {
    background-color: getvar($colors, "primary", "50");
    position: absolute;
    right: -105%;
    top: 0;
    height: 100vh;
    width: 100vw;
    transition: right 0.3s ease-in-out;
    z-index: 1;

    @include md {
      background-color: transparent;
      text-align: left;
      position: relative;
      height: auto;
      width: auto;
      right: 0;
      overflow-y: auto;
    }

    .button--ghost {
      color: getvar($colors, "neutral", "900");
    }

    .button--secondary {
      display: inline-block !important;
      text-align: center;
    }

    .button--isRegisterPage {
      @include md {
        padding: 0.75rem;
      }
    }

    .hamburger--isOpen {
      .sidebar__hamburger {
        padding-bottom: 0 !important;
      }
    }

    .hamburger__links-wrapper {
      padding: 4.5rem 1.5rem 4rem 1.5rem !important;
      overflow-y: scroll;
      @include md {
        overflow-y: auto;
        padding: 6rem 1.5rem 4rem 1.5rem !important;
      }

      .sidebar__hamburger {
        .sidebar__top {
          padding-top: 24px;

          @include md {
            padding-top: 0;
          }
        }
      }
    }
    .hamburger__links-wrapper::-webkit-scrollbar {
      display: none;
    }
  }

  &__links-wrapper {
    padding: space(4.5) space() space() space(3.5);
    height: 88vh;

    @include sm {
      padding: space(6) space() space() space(3.5);
      padding: 0px 1rem;
      height: 100%;

      display: flex;
      align-items: baseline;
    }

    #login {
      margin-bottom: 12px;
      @include md {
        margin-bottom: 0;
      }
    }
  }

  &__button {
    display: inline-flex;
    position: relative;
    color: getvar($colors, "primary", "500");
    cursor: pointer;
    z-index: 2;

    svg {
      height: space(1.5);
      width: space(1.5);
    }

    @include sm {
      display: none;
    }
  }

  &__button.dashboard {
    display: inline-flex;
    position: relative;
    color: getvar($colors, "primary", "500");
    cursor: pointer;
    z-index: 2;

    svg {
      height: space(1.5);
      width: space(1.5);
    }

    @include md {
      display: none;
    }
  }

  &--isOpen {
    .hamburger__links {
      right: 0;
    }
  }

  .button--secondary {
    min-width: auto;
  }
}

.overflowHidden {
  overflow: hidden;
}
