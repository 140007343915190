@import "src/themes/daikiri/styles/index.scss";
.footer {
  background-color: $footer-bg-color;
  padding-top: space(2.5);

  @include sm {
    padding-top: space(3.5);
  }

  &__copyright {
    background-color: $footer-copyright-bg-color;
  }

  &__wrapper {
    padding-bottom: space(0.5);

    @include sm {
      display: flex;
      padding-right: space(3) !important;
      display: flex;
      padding-bottom: space(1.5);
      padding-left: space(3) !important;
      gap: 70px;
    }

    @include md {
      gap: 100px;
    }
    @include lg {
      padding-bottom: space(2.5);
    }
  }

  &__wrapper-copyright {
    padding-top: space();
    padding-bottom: space();

    @include lg {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__section {
    @include sm {
      flex-direction: column;
    }
    img {
      width: 55%;
      margin-bottom: 18px;
      @include sm {
        width: 100%;
        margin-bottom: 0;
      }
    }

    &-left {
      flex: 1;
      display: flex;
      align-items: center;

      @include sm {
        flex-direction: column;
        justify-content: space-between;
        margin-right: 15px !important;
      }
      @include md {
        margin-right: 10px;
        max-width: 275px !important;
      }
      @include lg {
        margin-right: 0;
        align-self: center;
      }
    }
    &-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      gap: 1rem;

      @include sm {
        flex-direction: row;
        flex: 2;
        gap: space(4.5);
      }
      @include md {
        flex-direction: row;
        flex: 2;
        gap: space(3);
      }
    }

    &.cta-mobile {
      display: none;

      @include sm {
        display: block;
        width: 200px;
      }

      @include lg {
        display: none;
      }
    }

    &.cta {
      flex: 1;
      display: flex;
      align-items: center;

      @include sm {
        display: none;
      }

      @include lg {
        display: block;
        position: relative;
      }
    }

    &-cta-title {
      padding: space(1) space(0.5);
      width: 48%;
      margin-bottom: space(1.25);
      margin-top: space(0.5);
      background: getvar($colors, "primary", "50");
      @include sm {
        width: auto;
        margin-bottom: 0;
        margin-top: 0;
      }
      @include lg {
        padding: space(1);
        width: 180px;
        margin-bottom: 0;
        margin-top: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      p {
        font-family: getvar($typography, "font-family-titles");
        font-size: space(1);
        line-height: 22px;
        font-weight: 700;
        text-align: center;
      }
      a,
      button {
        width: 100% !important;
      }
    }
    &-column {
      flex: 1;
    }
  }

  &__logo {
    margin-bottom: space(2.5);

    @include sm {
      margin-bottom: 0;
    }
  }

  &__title {
    font-weight: bold;
    margin-bottom: space(1);

    @include sm {
      padding-left: 0;
      margin-top: space(0.5);
      margin-bottom: space(2);
    }
  }

  &__links {
    padding-left: 0 !important;

    @include sm {
      padding-left: 0;
      margin-bottom: space();
    }

    li {
      list-style: none;
      margin-bottom: space(1);

      @include sm {
        margin-bottom: space();
      }
      .link {
        color: $footer-link-color;
        min-height: auto !important;
        text-align: left !important;

        font-size: 16px !important;
        text-align: left !important;
        font-weight: 400 !important;
        line-height: 24px !important;
      }
    }
  }
  &__text {
    padding-bottom: space(0.5);
    text-align: center;
    font-size: space(0.875);
    line-height: space(1.125);
    @include sm {
      padding-bottom: 0;
    }
    @include md {
      font-size: space(1);
      line-height: space(1.5);
    }
  }
  &__text:last-child {
    padding-bottom: space(0.5);

    @include sm {
      padding-bottom: space(0);
    }
  }

  &__copyright {
    padding-bottom: space(0.5);
    text-align: center;
  }
}

.footerNoMB {
  margin-bottom: 0 !important;
}

.footer.pricing {
  padding-bottom: 85px;

  @include sm {
    padding-bottom: 0;
  }
}
