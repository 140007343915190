@import "src/themes/daikiri/styles/index.scss";
.header {
  background-color: getvar($colors, "neutral", "white");
  @extend .u-shadow-small;
  position: relative;
  z-index: 1;

  &__logo {
    align-items: flex-end;
    display: flex;
    position: relative;
    z-index: 2;
    div {
      display: flex;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding: 16px;

    @include sm {
      padding: 8px !important;
      min-height: 42px;
    }

    @include lg {
      padding: 8px 0px;
    }

    .nav__wrapper {
      display: flex;
      align-items: center;
    }
  }

  &__links {
    .button {
      margin-left: space(3);
    }

    .button--ghost {
      color: $header-button-ghost-color;
    }
  }
}
