@import "src/themes/daikiri/styles/index.scss";
.tooltip {
  display: inline-block;
  position: relative;
  text-align: center;

  .tooltip-text {
    min-width: 100px;
    max-width: 200px;
    padding: 10px;
    color: getvar($colors, "neutral", "800");
    background-color: getvar($colors, "primary", "50");
    border: 1px solid getvar($colors, "primary", "200");
    border-radius: 8px;
    position: absolute;
    z-index: 3;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    //Tooltip arrow
    i {
      position: absolute;
      overflow: hidden;
    }

    i::after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: getvar($colors, "primary", "50");
      border: 1px solid getvar($colors, "primary", "200");
    }

    &.top {
      top: -10px;
      left: 50%;
      transform: translate(-50%, -100%);
      i {
        top: 100%;
        left: 50%;
        margin-left: -12px;
        width: 24px;
        height: 12px;
      }
      i::after {
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    &.left {
      top: 50%;
      right: 100%;
      transform: translate(0, -50%);
      i {
        top: 50%;
        left: 100%;
        margin-top: -12px;
        width: 12px;
        height: 24px;
      }
      i::after {
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    &.bottom {
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      i {
        bottom: 100%;
        left: 50%;
        margin-left: -12px;
        width: 24px;
        height: 12px;
      }
      i::after {
        left: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
      }
    }

    &.right {
      top: 50%;
      left: 100%;
      transform: translate(0, -50%);

      i {
        top: 50%;
        right: 100%;
        margin-top: -12px;
        width: 12px;
        height: 24px;
      }

      i::after {
        left: 0;
        top: 50%;
        transform: translate(50%, -50%) rotate(-45deg);
      }
    }
  }
}
