@import "src/themes/daikiri/styles/index.scss";
.intro {
  background-color: getvar($colors, "neutral", "50");
  //padding: space(3) space();
  padding: space(1.75) space();
  text-align: center;
  margin-bottom: space(1.5);
  @include lg {
    padding: space(4) space();
    margin-bottom: space(2);
  }

  &__title {
    margin-bottom: 0;
  }

  &__subtitle {
    display: block;
    margin-top: space();
    white-space: pre-wrap;

    @include lg {
      margin-top: space(1);
      max-width: space(33);
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--subtitle {
    padding: space(3) space() space(1.5);

    @include lg {
      padding: space(4.5) space() space(1.5);
    }
  }
}
